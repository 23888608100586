<template>
    <div>
        <mercur-card class="mb-4">
            <p>
                Use this page to sync the Presta shops into Merchant Central.
                <br/>
                These shops will be used in order to correctly index product data across all available shops.
            </p>
            <mercur-button
                @click.native="sync()"
                class="btn btn-primary">
                Sync shops
                <i class="fas fa-download ml-2"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'ShopConfigPresta',
    methods: {
        sync () {
            return this.post(
                CONFIG.API.ROUTES.SHOP_CONFIG.SYNC,
                {},
                'Shops sync has been requested',
                'Something went wrong. Please try again.'
            )
        },
    },
}
</script>
